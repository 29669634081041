import React from "react";
import { Route, Switch } from "react-router-dom";

function Icon(props) {
  const { texxture, di, wholesale } = props.urls;
  const reidrect = (link) => {
    window.location.href = link;
    return null;
  };

  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/throwing-an-late-night-backyard-summer-party"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/throwing-a-late-night-backyard-summer-party`
            )
          }
        />
        <Route
          path="/what-do-i-put-on-my-coffee-table"
          exact
          component={() =>
            reidrect(`${texxture}/blogs/news/what-do-i-put-on-my-coffee-table`)
          }
        />
        <Route
          path="/a-family-room-tour-by-just-destiny"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/a-look-into-just-destinys-stylish-family-room`
            )
          }
        />
        <Route
          path="/how-to-host-an-outdoor-movie-night"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/how-to-host-an-outdoor-movie-night`
            )
          }
        />
        <Route
          path="/everyday-makeup-essentials"
          exact
          component={() =>
            reidrect(`${texxture}/blogs/news/how-to-stock-your-powder-room`)
          }
        />
        <Route
          path="/kitchen-essentials-for-the-upcoming-baking-season"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/kitchen-essentials-for-the-upcoming-baking-season`
            )
          }
        />
        <Route
          path="/fall-bonfire-essentials"
          exact
          component={() =>
            reidrect(`${texxture}/blogs/news/fall-bonfire-essentials`)
          }
        />
        <Route
          path="/a-neutral-natural-nursery-reveal"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/a-neutral-and-natural-nursery-reveal`
            )
          }
        />
        <Route
          path="/how-to-throw-a-fabulous-fall-soiree-your-friends-will-remember-by-just-destiny"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/how-to-throw-a-fabulous-fall-soiree-your-friends-will-remember-by-just-destiny`
            )
          }
        />
        <Route
          path="/our-new-favorite-thanksgiving-tradition-thankful-trees"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/our-new-favorite-thanksgiving-tradition-thankful-trees`
            )
          }
        />
        <Route
          path="/college-housewifes-la-rental-kitchen-makeover"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/college-housewifes-la-rental-kitchen-makeover`
            )
          }
        />
        <Route
          path="/a-fall-inspired-tablescape-for-this-years-friendsgiving"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/a-fall-inspired-tablescape-for-this-years-friendsgiving`
            )
          }
        />
        <Route
          path="/5-tips-for-your-most-organized-kitchen"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/5-tips-for-your-most-organized-kitchen`
            )
          }
        />
        <Route
          path="/elizabeths-easy-chai-tea-latte-mix-with-star-anise"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/elizabeths-easy-chai-tea-latte-mix-with-star-anise`
            )
          }
        />
        <Route
          path="/just-destinys-holiday-housewalk"
          exact
          component={() =>
            reidrect(`${texxture}/blogs/news/just-destinys-holiday-housewalk`)
          }
        />
        <Route
          path="/the-inspired-home-go-green-in-2020-by-using-these-cleaning-products"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/the-inspired-home-go-green-in-2020-by-using-these-cleaning-products`
            )
          }
        />
        <Route
          path="/creative-storage-solutions-for-small-kitchens"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/creative-storage-solutions-for-small-kitchens`
            )
          }
        />
        <Route
          path="/college-housewifes-bachelor-viewing-party"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/college-housewifes-bachelor-viewing-party`
            )
          }
        />
        <Route
          path="/5-eco-friendly-changes-you-can-make-in-your-every-day-routine"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/5-eco-friendly-changes-you-can-make-in-your-everyday-routine`
            )
          }
        />
        <Route
          path="/how-to-make-the-most-of-galentines-day"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/how-to-make-the-most-of-galentines-day`
            )
          }
        />
        <Route
          path="/how-to-reintroduce-spring-into-your-home"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/news/how-to-reintroduce-spring-into-your-home`
            )
          }
        />
        <Route
          path="/rounding-up-some-of-our-favorite-post-worthy-beverages"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/rounding-up-some-of-our-favorite-post-worthy-beverages`
            )
          }
        />
        <Route
          path="/how-to-keep-even-the-smallest-pantry-organized"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/how-to-keep-even-the-smallest-pantry-organized`
            )
          }
        />
        <Route
          path="/a-fun-and-fresh-laundry-room"
          exact
          component={() =>
            reidrect(
              `${texxture}/blogs/news/how-to-freshen-up-your-laundry-room`
            )
          }
        />

        <Route component={() => reidrect(`${di}/blogs/news`)} />
      </Switch>
    </React.Fragment>
  );
}

export default Icon;

//Route path={["/welcome", "/account", "/basket"]} component={Welcome} />
